export const PhotosData = [
	{ img: "images/photography/photo1.jpg" },
	{ img: "images/photography/photo2.jpg" },
	{ img: "images/photography/photo3.jpg" },
	{ img: "images/photography/photo4.jpg" },
	{ img: "images/photography/photo5.jpg" },
	{ img: "images/photography/photo6.jpg" },
	{ img: "images/photography/photo7.jpg" },
	{ img: "images/photography/photo8.jpg" },
	{ img: "images/photography/photo9.jpg" },
	{ img: "images/photography/photo10.jpg" },
];
